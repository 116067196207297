import jwt_decode from 'jwt-decode';
import { User } from './models/AccountModels';
import {
  COLUMN_EXPANDED_KEY,
  COLUMN_FROZEN_KEY,
  COLUMN_HIDING_KEY,
  COLUMN_ORDER_KEY,
  COLUMN_SIZING_KEY,
  KEY_VERSION,
  TABLE_FILTERED_PROPERTIES,
  TABLE_FIRST_SELECTED_PROPERTY,
  TABLE_HORIZONTAL_SCROLL_POS,
  TABLE_SORT_VALUES,
} from '../utils/Constants';
import { UserAPIs } from './apis/UserAPIs';
import { NetworkRequests } from './NetWorkRequests';
import TableUtils from '../components/table-components/TableUtils';

export class AuthActions {
  static saveUserTableSettings = (dataName: string, userAPIs: UserAPIs) => {
    const authActions = new AuthActions();
    const user = authActions.retrieveAuthenticatedUser();
    if (user) {
      const tableSettings = TableUtils.getTableSettings(dataName);

      userAPIs.updatesUserProfile(
        {
          table_settings: {
            ...user.table_settings,
            ...tableSettings,
          },
        },
        { id: user?.id },
        (is_successful: boolean, user_or_error: any) => {
          if (is_successful) {
            if (user_or_error?.tokens)
              authActions.storeAuthenticationTokens(user_or_error?.tokens);
            if (user_or_error?.table_settings) {
              Object.entries(user_or_error?.table_settings ?? {})?.forEach(
                ([key, value]: [string, any]) => {
                  localStorage[key] = JSON.stringify(value);
                }
              );
            }
            // To be removed later
            authActions.clearTableCache(dataName);
          } else {
            console.log(user_or_error);
          }
        }
      );
    }
  };

  static saveUserKanbanSettings = (kanbanName: string, userAPIs: UserAPIs) => {
    const authActions = new AuthActions();
    const user = authActions.retrieveAuthenticatedUser();
    if (user) {
      const kanbanSettings = TableUtils.getKanbanSettings(kanbanName);

      userAPIs.updatesUserProfile(
        {
          kanban_settings: {
            ...user.kanban_settings,
            ...kanbanSettings,
          },
        },
        { id: user?.id },
        (is_successful: boolean, user_or_error: any) => {
          if (is_successful) {
            if (user_or_error?.tokens)
              authActions.storeAuthenticationTokens(user_or_error?.tokens);
            if (user_or_error?.kanban_settings) {
              Object.entries(user_or_error?.kanban_settings ?? {})?.forEach(
                ([key, value]: [string, any]) => {
                  localStorage[key] = JSON.stringify(value);
                }
              );
            }
          } else {
            console.log(user_or_error);
          }
        }
      );
    }
  };

  storeAuthenticationTokens(tokens: any) {
    if (![undefined, ''].includes(tokens?.access_token)) {
      localStorage.access_token = tokens?.access_token;
      localStorage.refresh_token = tokens?.refresh_token;
    }
  }

  retrieveAuthenticationTokens() {
    return {
      access_token: localStorage.access_token || '_',
      refresh_token: localStorage.refresh_token || '_',
    };
  }

  retrieveAuthenticatedUser() {
    const tokens = this.retrieveAuthenticationTokens();
    const { access_token }: any = tokens;
    try {
      const decode: any = jwt_decode(access_token);
      return new User(decode);
    } catch (e) {
      return null;
    }
  }

  isAuthenticated() {
    const tokens = this.retrieveAuthenticationTokens();
    const { access_token, refresh_token }: any = tokens;

    try {
      const accessDecode: any = jwt_decode(access_token);
      const refreshDecode: any = jwt_decode(refresh_token);
      if (access_token === '' || refresh_token === '') {
        return false;
      }
      const accessIsExpired = new Date().getTime() > accessDecode.exp * 1000;
      const refreshIsExpired = new Date().getTime() > refreshDecode.exp * 1000;

      if (accessIsExpired && refreshIsExpired) {
        // Both tokens are expired, user is not authenticated
        return false;
      } else if (accessIsExpired && !refreshIsExpired) {
        // Access token expired but refresh token is valid, refresh access token
        const ntr = new NetworkRequests();
        ntr.refreshTokenRequest(() => null);
        return true; // Temporarily return true while refreshing
      } else {
        // Both tokens are valid, user is authenticated
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  clearTableCache(dataName: string) {
    localStorage.removeItem(
      `${dataName}${COLUMN_HIDING_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_SIZING_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_EXPANDED_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_ORDER_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    localStorage.removeItem(
      `${dataName}${COLUMN_FROZEN_KEY}`?.replace(`_v${KEY_VERSION}`, '') ?? ''
    );

    Array.from(Array(KEY_VERSION - 1).keys()).forEach((version) => {
      localStorage.removeItem(
        `${dataName}${COLUMN_HIDING_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}_tdc_columns_visibility_v${KEY_VERSION}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${COLUMN_SIZING_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${COLUMN_EXPANDED_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${COLUMN_ORDER_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${COLUMN_FROZEN_KEY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${TABLE_FIRST_SELECTED_PROPERTY}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${TABLE_FILTERED_PROPERTIES}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );

      localStorage.removeItem(
        `${dataName}${TABLE_HORIZONTAL_SCROLL_POS}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
      localStorage.removeItem(
        `${dataName}${TABLE_SORT_VALUES}`?.replace(
          `_v${KEY_VERSION}`,
          `_v${version}`
        ) ?? ''
      );
    });
  }
}
