import {
  ADD_TO_WATCH_LIST,
  COLUMN_EXPANDED_KEY,
  COLUMN_FROZEN_KEY,
  COLUMN_HIDING_KEY,
  COLUMN_ORDER_KEY,
  COLUMN_SIZING_KEY,
  DEV_EMAIL_ACTION,
  KANBAN_COLUMN_COLLAPSE_KEY,
  KEY_VERSION,
  LEAD_EMAIL_ACTION,
  REQUEST_BY_EMAIL_ACTION,
  REQUEST_EMAIL_ACTION,
  SALES_EMAIL_ACTION,
  TABLE_FILTERED_PROPERTIES,
  TABLE_FIRST_SELECTED_PROPERTY,
  TABLE_HORIZONTAL_SCROLL_POS,
  TABLE_SORT_VALUES,
  TEAM_EMAIL_ACTION,
} from '../../utils/Constants';

class TableUtils {
  static EmailCommentActions(dataName: string) {
    let actions: EmailActionType[] = [
      {
        name: LEAD_EMAIL_ACTION,
        text: 'Request update from task lead',
        comment: 'Please update.',
      },
      {
        name: TEAM_EMAIL_ACTION,
        text: 'Request update from team',
        comment: 'Please update.',
      },
      {
        name: DEV_EMAIL_ACTION,
        text: 'Request update from dev lead',
        comment: 'Please update.',
      },
      {
        name: SALES_EMAIL_ACTION,
        text: 'Request update from sales lead',
        comment: 'Please update.',
      },
      {
        name: REQUEST_EMAIL_ACTION,
        text: 'Create recurring update request',
      },
      {
        name: REQUEST_BY_EMAIL_ACTION,
        text: 'Request update via email',
      },
      {
        name: ADD_TO_WATCH_LIST,
        text: 'Add to my watch list',
      },
    ];
    if (dataName === 'Project') {
      actions = actions?.filter(
        (a: EmailActionType) =>
          ![LEAD_EMAIL_ACTION, ADD_TO_WATCH_LIST].includes(a.name)
      );
    }
    if (dataName === 'TaskWatchList') {
      actions = actions?.filter(
        (a: EmailActionType) => ![ADD_TO_WATCH_LIST].includes(a.name)
      );
    }
    return actions;
  }

  static getKanbanSettings(kanbanName: string, keyVersion = true) {
    const versionFix = `_v${KEY_VERSION}`;
    const kanbanColsCollapse: any = JSON.parse(
      localStorage[`${kanbanName}${KANBAN_COLUMN_COLLAPSE_KEY}`] || '{}'
    );
    return {
      [`${kanbanName}${KANBAN_COLUMN_COLLAPSE_KEY.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        kanbanColsCollapse,
    };
  }
  static getTableSettings(dataName: string, keyVersion = true) {
    const versionFix = `_v${KEY_VERSION}`;
    const colHides: any = JSON.parse(
      localStorage[`${dataName}${COLUMN_HIDING_KEY}`] || '{}'
    );
    const colSizes: any = JSON.parse(
      localStorage[`${dataName}${COLUMN_SIZING_KEY}`] || '{}'
    );
    const expandedRows = JSON.parse(
      localStorage[`${dataName}${COLUMN_EXPANDED_KEY}`] || '[]'
    );
    const colsOrders: any = JSON.parse(
      localStorage[`${dataName}${COLUMN_ORDER_KEY}`] || '[]'
    );
    const colsFrozen: any = JSON.parse(
      localStorage[`${dataName}${COLUMN_FROZEN_KEY}`] || '{}'
    );
    const tableSorts: any = JSON.parse(
      localStorage[`${dataName}${TABLE_SORT_VALUES}`] || '{}'
    );
    const tableFilters: any = JSON.parse(
      localStorage[`${dataName}${TABLE_FILTERED_PROPERTIES}`] || '{}'
    );
    const tableFirstSelectedProperty: any =
      localStorage[`${dataName}${TABLE_FIRST_SELECTED_PROPERTY}`] || '';

    const savedScrollPosition =
      localStorage[`${dataName}${TABLE_HORIZONTAL_SCROLL_POS}`];

    return {
      [`${dataName}${COLUMN_HIDING_KEY.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        colHides,
      [`${dataName}${COLUMN_SIZING_KEY.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        colSizes,
      [`${dataName}${COLUMN_EXPANDED_KEY.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        expandedRows,
      [`${dataName}${COLUMN_ORDER_KEY.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        colsOrders,
      [`${dataName}${COLUMN_FROZEN_KEY.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        colsFrozen,
      [`${dataName}${TABLE_SORT_VALUES.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        tableSorts,
      [`${dataName}${TABLE_FILTERED_PROPERTIES.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        tableFilters,
      [`${dataName}${TABLE_HORIZONTAL_SCROLL_POS.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        savedScrollPosition,
      [`${dataName}${TABLE_FIRST_SELECTED_PROPERTY.replace(`${versionFix}`, keyVersion ? versionFix : '')}`]:
        tableFirstSelectedProperty,
    };
  }

  static getColsHidden(dataName: string, pageState?: any) {
    return pageState
      ? pageState[`${dataName}${COLUMN_HIDING_KEY}`]
      : JSON.parse(localStorage[`${dataName}${COLUMN_HIDING_KEY}`] || '{}');
  }

  static getColsFrozen(dataName: string, pageState?: any) {
    return pageState
      ? pageState[`${dataName}${COLUMN_FROZEN_KEY}`]
      : JSON.parse(localStorage[`${dataName}${COLUMN_FROZEN_KEY}`] || '{}');
  }

  static getColsOrders(dataName: string, pageState?: any) {
    return pageState
      ? pageState[`${dataName}${COLUMN_ORDER_KEY}`]
      : JSON.parse(localStorage[`${dataName}${COLUMN_ORDER_KEY}`] || '[]');
  }

  static getColSizes(dataName: string, pageState?: any) {
    return pageState
      ? pageState[`${dataName}${COLUMN_SIZING_KEY}`]
      : JSON.parse(localStorage[`${dataName}${COLUMN_SIZING_KEY}`] || '{}');
  }

  static calculateTextHeight(
    text: string,
    containerWidth: number,
    options: any = {}
  ) {
    const { fontSize = '14px', lineHeight = 'normal' } = options;

    // Create a temporary div element for measuring
    const tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.width = `${containerWidth}px`;
    tempDiv.style.fontSize = fontSize;
    tempDiv.style.lineHeight = `${lineHeight}`;
    tempDiv.style.whiteSpace = 'normal'; // Allow wrapping
    tempDiv.style.wordWrap = 'break-word'; // Break long words if necessary
    tempDiv.textContent = text;

    // Append the div to the body to get its rendered height
    document.body.appendChild(tempDiv);
    const height = tempDiv.scrollHeight; // Get the required height for the text
    document.body.removeChild(tempDiv); // Clean up by removing the temporary div

    return height > 25 ? height + 4 : 26;
  }
}

export type EmailActionType = {
  name: string;
  text: string;
  comment?: string;
  showActionBtn?: boolean;
  disabled?: boolean;
};
export type DataTableHeaderType = {
  property: string;
  data: any[];
  originalData: any[];
  setData: (data: any) => void;
  setDefaultSortValues: (data: any) => void;
  defaultSortValues: any;
  firstSelectedProperty: string;
  setFirstSelectedProperty: (data: any) => void;
  filteredPropertyData?: any;
  setFilteredPropertyData: (data: any) => void;
  setClickedColumnId: (data: any) => void;
  filterOpen: boolean;
  setFilterOpen: (data: boolean) => void;
  setDefaultColHidden?: (data: any) => void;
  setIsExportDialogOpen?: (data: boolean) => void;
  setIsSaveViewDialogOpen?: (data: boolean) => void;
  setIsOpenFileUpload?: (data: boolean) => void;
};
export default TableUtils;
